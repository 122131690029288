import styled from '@emotion/styled'
import { FlexBox } from 'app/components/Layout/FlexBox'
import { useVocabularyData } from 'app/utils/vocabulary'
import { compact, uniqBy } from 'lodash'
import React, { memo } from 'react'

import { MenuItem, Props as MenuItemProps } from './MenuItem'

export interface Props {
  cin?: string
  languageCode: string
  policyMenu?: { [key: string]: MenuItemProps[] }[]
  vat?: string
}

export const Bottom = memo(function Bottom({
  cin,
  languageCode,
  policyMenu,
  vat,
}: Props) {
  const policyNav = compact(
    compact(policyMenu?.map((lang) => lang[languageCode as 'it-IT']))[0],
  ).filter((t) => t.languageCode === languageCode)

  return (
    <Container>
      <SiteName>
        {vat
          ? `GE.S.T.A Srl - ${useVocabularyData('vat', languageCode)} ${vat}`
          : null}
        {cin ? ` - ${useVocabularyData('cin', languageCode)}: ${cin}` : null}
      </SiteName>

      <Wrapper row space="between" wrap>
        {policyMenu ? (
          <Policy dial={2} row wrap>
            {uniqBy(policyNav, 'title').map((item, index) => (
              <MenuItem key={index} {...item} />
            ))}
            <MenuItem
              title={useVocabularyData('stay-insurance', languageCode)}
              URL="https://www.aga-affiliate.it/ita/hotelpuntanegra"
            />
          </Policy>
        ) : null}

        <Credits href="https://www.qnt.it" rel="noopener" target="_blank">
          webdesign QNT Hospitality
        </Credits>
      </Wrapper>
    </Container>
  )
})

const Container = styled.div`
  width: 100%;
  border-top: 0.125rem solid
    ${({ theme }) => theme.colors.variants.neutralLight2};
  color: ${({ theme }) => theme.colors.variants.neutralDark2};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 0.8125rem;
  line-height: 1.875rem;
  margin-top: 6rem;
  padding-top: 1.75rem;

  @media (max-width: 767px) {
    margin-top: 3.75rem;
  }
`

const SiteName = styled.div`
  margin-bottom: 0.9375rem;
`

const Wrapper = styled(FlexBox)`
  @media (max-width: 767px) {
    display: block;
  }
`

const Policy = styled(FlexBox)`
  @media (max-width: 767px) {
    display: block;
  }
`

const Credits = styled.a`
  font-weight: 700;
  transition: 0.15s ease-in-out;
  &:hover {
    color: ${({ theme }) => theme.colors.variants.primaryDark1};
  }
`
