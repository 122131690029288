import styled from '@emotion/styled'
import { Logo } from 'app/components/Icons'
import { FlexBox } from 'app/components/Layout/FlexBox'
import { useVocabularyData } from 'app/utils/vocabulary'
import React, { memo } from 'react'

import { Bottom } from './Bottom'
import { Contacts } from './Contacts'
import { Props as MenuItemProps } from './MenuItem'
import { Social } from './Social'

export interface Props {
  address?: string
  bookingEmail?: string
  cin?: string
  facebookURL?: string
  infoEmail?: string
  instagramURL?: string
  youtubeURL?: string
  languageCode: string
  managementEmail?: string
  phone?: string
  policyMenu?: { [key: string]: MenuItemProps[] }[]
  siteName?: string
  vat?: string
  whatsappURL?: string
}

export const Footer = memo(function Footer({
  address,
  bookingEmail,
  cin,
  facebookURL,
  infoEmail,
  instagramURL,
  youtubeURL,
  languageCode,
  managementEmail,
  phone,
  policyMenu,
  siteName,
  vat,
  whatsappURL,
}: Props) {
  return (
    <Container>
      <Wrapper row space="between" wrap>
        <Cell>
          <Brand>
            <Logo />
          </Brand>

          <Social
            instagramURL={instagramURL}
            facebookURL={facebookURL}
            youtubeURL={youtubeURL}
            whatsappURL={whatsappURL}
          />
        </Cell>

        <Cell>
          {siteName ? <Label>{siteName}</Label> : null}
          {address ? (
            <Address dangerouslySetInnerHTML={{ __html: address }} />
          ) : null}
        </Cell>

        <Cell>
          <Label>{useVocabularyData('contacts', languageCode)}</Label>
          <Contacts
            bookingEmail={bookingEmail}
            infoEmail={infoEmail}
            languageCode={languageCode}
            managementEmail={managementEmail}
            phone={phone}
          />
        </Cell>
        <Cell className="footer-logos">
          <LogoFooter
            src="/logo-parco.webp"
            alt="Parco"
            width="85"
            height="120"
          />
        </Cell>

        <Bottom
          languageCode={languageCode}
          policyMenu={policyMenu}
          vat={vat}
          cin={cin}
        />
      </Wrapper>
    </Container>
  )
})

const Container = styled.section`
  background: ${({ theme }) => theme.colors.variants.neutralLight4};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  padding: 5.6875rem 1.875rem 1.5625rem;

  @media (max-width: 1023px) {
    padding: 3.75rem 1.25rem 1.5625rem;
  }
`

const Wrapper = styled(FlexBox)`
  max-width: 67.5rem;
  margin: auto;

  @media (max-width: 767px) {
    justify-content: center;
    text-align: center;
  }
`

const Cell = styled.div`
  margin-top: 2.5rem;

  @media (max-width: 767px) {
    width: 100%;
  }

  &.footer-logos {
    @media (max-width: 991px) {
      width: 100%;
    }
  }
`

const LogoFooter = styled.img``

const Brand = styled(FlexBox)`
  svg {
    width: 5.375rem;
    height: 6.625rem;
    fill: ${({ theme }) => theme.colors.variants.primaryDark1};
  }

  @media (max-width: 767px) {
    svg {
      margin: auto;
    }
  }
`

const Label = styled.div`
  color: ${({ theme }) => theme.colors.variants.primaryDark1};
  font-size: 0.8125rem;
  font-weight: 700;
  letter-spacing: 0.1625rem;
  line-height: 1.125rem;
  margin-bottom: 1.125rem;
  text-transform: uppercase;
`

const Address = styled.div`
  color: ${({ theme }) => theme.colors.variants.neutralDark2};
  font-size: 0.9375rem;
  line-height: 1.875rem;
`
